
export default {
  name: 'FCLogo',
  props: {
    type: {
      default: 'dark',
      required: false,
      validator: function (value) {
        return [
          'dark',
          'light',
          'regular'
        ].includes(value)
      }
    }
  }
}
